<template>
  <div class="flex mb-3">
    <gl-icon
      :height="16"
      :name="getIcon"
      :width="16"
    />
    <div
      class="validate-block-message ml-2"
      :class="{
        'warning-text-red': !valid && turnOn,
        'white-space-pre-line': preLineMsg,
      }"
    >
      {{ msg }}
    </div>
  </div>
</template>

<script>
import GlIcon from '@/components/gl-icon'
export default {
  components: {
    GlIcon,
  },
  props: {
    msg: {
      type: String,
      default: '',
    },
    turnOn: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    preLineMsg: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getIcon() {
      if (!this.turnOn) {
        return 'def-report'
      }

      return this.valid ? 'succeed-report' : 'warn-report'
    },
  },
}
</script>

<style scoped>
.validate-block-message {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}
</style>
