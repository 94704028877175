<template>
  <div class="login">
    <div class="login__form-logo mb-2">
      <SatoshiEyeLogo
        class="pointer"
        @click="$router.push({ name: 'landing-page' })"
      />
    </div>
    <div
      v-if="isStart"
      class="login__form"
    >
      <div class="login__form-wrapper login__form-wrapper--register">
        <h2 class="login__title">
          {{ resetToken ? 'Set New Password' : 'Get Started Now' }}
        </h2>
        <div class="login__sub-title">
          {{
            resetToken
              ? 'Create new password to access your account'
              : 'Enter your credentials to access your account'
          }}
        </div>

        <gl-form
          full-submit
          ok-only
          :side-valid="isPasswordFormValid"
          :submit-title="resetToken ? 'Reset Password' : 'Sign Up'"
          @errors="setHandleError"
          @submit="handleSubmit"
        >
          <gl-input
            v-if="!resetToken"
            v-model="email"
            autocomplete="email"
            class="login__input"
            is-block-error-style
            label="Email"
            name="Email"
            placeholder="email@example.com"
            rules="required|email"
          />
          <gl-input
            v-model="password"
            autocomplete="current-password"
            class="login__input"
            hide-control
            :label="resetToken ? 'New password' : 'Password'"
            name="Password"
            placeholder="At least 14 characters"
            rules="required"
            type="password"
            vid="password"
          />
          <div class="mb-3">
            <ValidateBlockWrapper
              :password="password"
              pre-line-msg
            />
          </div>
          <gl-input
            v-model="samePassword"
            autocomplete="current-password"
            class="login__input"
            hide-control
            :label="resetToken ? 'Confirm new password' : 'Confirm password'"
            name="Password confirmation"
            placeholder="At least 14 characters"
            rules="required|confirmed:password"
            type="password"
          />
          <div
            v-if="!resetToken"
            class="mb-4"
          >
            <gl-checkbox
              v-model="agreePrivacy"
              class="mb-2"
            />
            <div class="ml-4 m-fs-14">
              I agree to the
              <router-link
                class="decoration-none accent-text-link bold-600"
                target="_blank"
                :to="{ name: 'terms-of-use' }"
              >
                Terms
              </router-link>
              &
              <router-link
                class="decoration-none accent-text-link bold-600"
                target="_blank"
                :to="{ name: 'privacy-policy' }"
              >
                Privacy
              </router-link>
              &
              <router-link
                class="decoration-none accent-text-link bold-600"
                target="_blank"
                :to="{ name: 'cookie-policy' }"
              >
                Cookies
              </router-link>
            </div>
          </div>
        </gl-form>

        <div
          v-if="getGoogleLoginFeatureFlag && !resetToken"
          class="login__google"
        >
          <div class="login__google-text">
            or
          </div>
          <div
            class="login__google-button"
            :class="{'login__google-button--active' : agreePrivacy}"
          >
            <GoogleLogin @googleAuth="sendGoogleToken" />
          </div>
        </div>

        <GlActionLink
          bold
          class="mt-4 m-fs-14"
          label="Have an account?"
          link-text="Log In"
          @click="$router.push({ name: 'login' })"
        />
      </div>
    </div>
    <div
      v-if="isWelcome"
      class="login__form"
    >
      <div class="login__form-wrapper login__form-wrapper--register">
        <h2 class="login__title">
          Welcome onboard!
        </h2>
        <div class="login__sub-title">
          Thank you for joining the SatoshiEye community <br>
          We’ve sent email to
          <strong class="link display-inline">{{ email }}</strong> to confirm
          the validity of your email address. After receiving the email follow
          the link provided to complete your registration
        </div>

        <button
          class="gl-button gl-button--dark gl-form__button gl-button--padder full-submit"
          @click="$router.push({ name: 'login' })"
        >
          Back to Log In
        </button>

        <div class="text-center link bold-600 mt-4">
          <div
            v-if="isTimerEnd"
            @click="resendEmail"
          >
            Resend email
          </div>
          <GlTimer
            v-else
            :timer-count="60"
            @end-timer="isTimerEnd = true"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isThankYou"
      class="login__form"
    >
      <div class="login__form-wrapper login__form-wrapper--register">
        <div>
          <h2 class="login__title">
            <gl-icon
              :height="44"
              name="success"
              style="margin-bottom: -8px"
              :width="44"
            />
            Thank you
          </h2>
          <div class="login__sub-title">
            Your email address has been verified
          </div>
        </div>

        <button
          class="gl-button gl-button--dark gl-form__button gl-button--padder full-submit"
          @click="$router.push({ name: 'login' })"
        >
          Back to Log In
        </button>
      </div>
    </div>
    <div class="login__bottom">
      SATOSHI EYE © {{ getFullYear }} ALL RIGHTS RESERVED
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapMutations, mapGetters } from 'vuex'
// Components
import GlForm from '@/components/gl-form'
import SatoshiEyeLogo from '@/assets/svg/header/SatoshiEyeLogo.svg?inline'
import GlInput from '@/components/gl-input'
import ValidateBlockWrapper from '@/pages/register/components/ValidateBlockWrapper'
import GlCheckbox from '@/components/gl-checkbox'
import GlLink from '@/components/gl-link'
import GlTimer from '@/components/gl-timer'
import GlActionLink from '@/components/gl-action-link'
import GlIcon from '@/components/gl-icon'
import GoogleLogin from '../login/components/GoogleLogin'
// Utils
import { nameHere, hasNumber, checkSpecSymbol } from '@/utils/checks'

export default {
  components: {
    GlForm,
    GlInput,
    SatoshiEyeLogo,
    ValidateBlockWrapper,
    GlCheckbox,
    GlLink,
    GlTimer,
    GlActionLink,
    GlIcon,
    GoogleLogin,
  },
  data() {
    return {
      email: '',
      password: '',
      samePassword: '',
      resetToken: '',
      verified: '',
      errors: {},
      agreePrivacy: false,
      isStart: false,
      isWelcome: false,
      isThankYou: false,
      isTimerEnd: false,
    }
  },
  computed: {
    ...mapGetters('analytics', ['getGoogleLoginFeatureFlag']),
    getFullYear() {
      return new Date().getFullYear()
    },
    isPasswordFormValid() {
      return (
        !this.checkSpecSymbol(this.password) ||
        !this.hasNumber(this.password) ||
        !this.nameHere(this.password) ||
        this.password.length < 14 ||
        this.password !== this.samePassword ||
        (!this.checkEmail(this.email) && !this.resetToken) ||
        (!this.agreePrivacy && !this.resetToken)
      )
    },
  },
  async created() {
    this.email = this.$route.query.email || ''
    this.verified = this.$route.query.verificationCode || ''

    if (this.$route.name === 'reset-password') {
      this.resetToken = this.$route.query.verificationCode || ''
      this.isStart = true
    } else if (this.$route.name === 'confirm' && this.verified) {
      await this.confirmEmail({
        email: this.email.toLowerCase(),
        verificationCode: this.verified,
      })
        .then(() => {
          this.isThankYou = true
        })
        .catch(() => {
          this.$router.push({ name: 'login' })
        })
    } else {
      this.isStart = true
    }
  },
  methods: {
    ...mapMutations({
      REMOVE_USER_TOKEN: 'user/REMOVE_USER_TOKEN',
      REMOVE_USER_ID: 'user/REMOVE_USER_ID',
    }),
    ...mapActions('user', [
      'register',
      'resendConfirmation',
      'confirmEmail',
      'getMe',
      'resetPassword',
    ]),
    nameHere,
    hasNumber,
    checkSpecSymbol,
    async handleSubmit() {
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[:\\/*&^%$#@!?-]).{14,}$/.test(this.password)) {
        this.$toast.add({
            severity: 'error',
            summary: 'Error Message',
            detail: 'Incorrect password',
            life: 3000,
        });
        return;
      }

      await this.clearAuthData()
      if (this.resetToken) {
        await this.resetPassword({
          email: this.email.toLowerCase(),
          password: this.password,
          verificationCode: this.resetToken,
        })
          .then(e => {
            this.$toast.add({
              severity: 'success',
              summary: 'Success Message',
              detail: e?.data,
              life: 3000,
            })
            this.$router.push({ name: 'login' })
          })
          .catch(() => {
            this.$router.push({ name: 'login' })
          })
      } else {
        await this.register({
          email: this.email.toLowerCase(),
          password: this.password,
          username: this.email.toLowerCase(),
        }).then(() => {
          this.isTimerEnd = false
          this.isStart = false
          this.isWelcome = true
        })
      }
    },
    async resendEmail() {
      await this.clearAuthData()
      await this.resendConfirmation({
        email: this.email.toLowerCase(),
      }).then(e => {
        this.isTimerEnd = false
        this.$toast.add({
          severity: 'success',
          summary: 'Success Message',
          detail: e?.data,
          life: 3000,
        })
      })
    },
    clearAuthData() {
      this.REMOVE_USER_TOKEN()
      this.REMOVE_USER_ID()
    },
    setHandleError(err) {
      this.errors = err
    },
    checkEmail(email) {
      return Boolean(email && this.errors.Email && !this.errors.Email[0])
    },
    async sendGoogleToken(token) {
      await this.clearAuthData()
      await this.register({token})
        .then(() => {
          this.isTimerEnd = false
          this.isStart = false
          this.isWelcome = true
        })
    },
  },
}
</script>

<style>
.login__google {
  padding-top: 16px;
}
.login__google-button {
  opacity: 0.5;
  pointer-events: none;
}
.login__google-button--active {
  opacity: 1;
  pointer-events: all;
}
.login__google-text {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  position: relative;
}
.login__google-text:before {
  content: "";
  width: 32px;
  height: 1px;
  background-color: var(--dark);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(calc(-100% - 16px), -50%);
}
.login__google-text:after {
  content: "";
  width: 32px;
  height: 1px;
  background-color: var(--dark);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(calc(100% + 16px), -50%);
}
</style>