

export const nameHere = str => {
  return Boolean(str.match(/[a-z]/) && str.match(/[A-Z]/))
}

export const hasNumber = str => {
  return /\d/.test(str)
}

export const checkSpecSymbol = str => {
  const re = /^(?=.*\d)(?=.*[:/\-*&^%$#@!?])/;
  return re.test(str);
}
