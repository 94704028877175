<template>
  <div>
    <validateBlock
      msg="At least 14 characters"
      :turn-on="Boolean(password)"
      :valid="password.length >= 14"
    />
    <validateBlock
      msg="Uppercase and lowercase letters"
      :turn-on="Boolean(password)"
      :valid="nameHere(password)"
    />
    <validateBlock
      msg="At least 1 digit"
      :turn-on="Boolean(password)"
      :valid="hasNumber(password)"
    />
    <validateBlock
      msg="At least 1 special character from the following set:
             : / - * & ^ % $ # @ ! ?"
      :pre-line-msg="preLineMsg"
      :turn-on="Boolean(password)"
      :valid="checkSpecSymbol(password)"
    />
  </div>
</template>

<script>
//Components
import validateBlock from '@/pages/register/components/validateBlock'

// Utils
import { nameHere, hasNumber, checkSpecSymbol } from '@/utils/checks'

export default {
  components: {
    validateBlock,
  },
  props: {
    password: {
      type: String,
      default: '',
    },
    preLineMsg: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    nameHere,
    hasNumber,
    checkSpecSymbol,
  },
}
</script>
